.nav-bar {
  background-color: black;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.image-section {
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;

}

.image {
  width: 110px;
}

.nav-links {
  grid-column: 3/4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links {
  text-decoration: none;
  color: white;
  margin-right: 10px;
}

.links:hover {
  color: goldenrod;
}
