body {
  margin: 0;
  box-sizing: border-box;
}

.project-section {
  background-color: goldenrod;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  border-bottom: 1px solid black;
}

.template-section {
  text-align: center;
  width: 100vw;
  font-size: 25px;
  margin-bottom: 100px;
}

.template-btn {
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
  font-size: 15px;
  background-color: white;
  cursor: pointer;
  text-decoration: none;
}

.template-btn:hover {
  background-color: rgb(209, 206, 206);
  color: red;
}