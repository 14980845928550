.footer {
  background-color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.copy-right {
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.copy {
  color: white;
}

.github {
  color: white;
  margin-right: 20px;
}

.twitter {
  color: white;
}

.linked {
  color: white;
  margin-right: 20px;
}

.social-links {
  grid-column: 3/4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.github:hover {
  color: goldenrod;
}

.linked:hover {
  color: goldenrod;
}

.twitter:hover {
  color: goldenrod;
}