.contact-me-section {
  background-color: goldenrod;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
}

.contact-section {
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-me {
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.label {
  color: white;
}

.name {
  width: 400px;
  height: 30px;
  background-color: black;
  color: white;
}

#email {
  width: 400px;
  height: 30px;
  background-color: black;
  color: white;
}

#message {
  width: 500px;
  height: 150px;
  background-color: black;
  color: white;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  list-style: none;
}

.input-btn {
  width: 150px;
  height: 40px;
  border-radius: 5px;
}

.input-btn:hover {
  background-color: greenco;
  color: white;
}

.email-me {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  font-size: 20px;
}

.email-add {
  text-decoration: none;
  color: black;
}

.email-add:hover {
  color: red;
}

.address {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  font-size: 20px;
}

.contact-me-header {
  text-align: center;
}

@media only screen and (max-width: 798px) {
  .name {
    width: 300px;
  }

  #email {
    width: 300px;
  }

  #message {
    width: 400px;
  }
}

@media only screen and (max-width: 656px) {
  .name {
    width: 200px;
  }

  #email {
    width: 200px;
  }

  #message {
    width: 300px;
  }
}

@media only screen and (max-width: 694px) {
  .contact-me-section {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

.contact-section {
  grid-column: 1/3;
  grid-row: 1/2;
}

.contact-me {
  grid-row: 2/3;
  grid-column: 1/3;
}
}
