.about {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px;
}

.about-heading {
  text-align: center;
}

.skills {
  font-size: 20px;
  list-style: none;
}

.experience  {
  font-size: 20px;
  list-style: none;
}

.cinque-links {
  color: black;
  margin: 10px;
}

.cinque-links:hover {
  color: white;
}