body {
  background-color: goldenrod;
}

.heading {
  text-align: center;
  height: 100px;
}

.section {
  display: grid;
  height: 1500px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr ;
  
}

.finesse {
  grid-column: 1/2;
  background: url(../../Assets/FINESSE.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: black;
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.finesse-head {
  float: right;
  text-decoration: none;
  color: gold;
  margin-right: 10px;
  text-shadow: 1px 1px 1px black;
}

.finesse-head:hover {
  color: rgb(112, 100, 29);
}

.lion {
  grid-column: 2/3;
  background: url(../../Assets/lion.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: white;
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.lion-head {
  float: right;
  margin-right: 10px;
  color: black;
  text-shadow: 1px 1px 1px black;
}

.lion-head:hover {
  color: gold;
}

.morning {
  grid-column: 3/4;
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.morning-logo {
  background: url(../../Assets/morning-blog.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 500px;
}

.morning-head {
  float: right;
  margin-right: 10px;
  color: gold;
  text-shadow: 1px 1px 1px black;
}

.morning-head:hover {
  color: black;
}

.elite {
  grid-column: 1/2;
  background: url(../../Assets/elite.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: black;
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.elite-head {
  float: right;
  margin-right: 10px;
  color: red;
}

.elite-head:hover {
  color: white;
}

.moon {
  grid-column: 2/3;
  background: linear-gradient(purple, blue);
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.moon-logo {
  background: url(../../Assets/moon-logo.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 400px;
}

.moon-head {
  float: right;
  margin-right: 10px;
  color: whitesmoke;
}

.moon-head:hover {
  color: black;
}

.ambition {
  grid-column: 3/4;
  background: rgb(49, 255, 188);
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.ambition-logo {
  background: url(../../Assets/ambition.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 400px;
}

.ambition-head {
  float: right;
  margin-right: 10px;
  color: rgb(83, 77, 194)
}

.ambition-head:hover {
  color: white;
}

.store {
  grid-column: 1/2;
  background: black;
  border-radius: 5px;
  width: 400px;
  border: 1px solid black;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-decoration: none;
}

.store-logo {
  background: url(../../Assets/store-logo.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 400px;
}

.store-head {
  float: right;
  margin-right: 10px;
  color: rgb(112, 155, 55);
}

.store-head:hover {
  color: white;
}