.banner {
  background-color: black;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.banner-img {
  width: 250px;
  border-radius: 50%;
}

.banner-heading {
  color: goldenrod;
}

.check {
  text-decoration: none;
  color: gold;
}

.check:hover {
  color: red;
}

.banner-links-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-links {
  border: 1px solid white;
  text-decoration: none;
  height: 40px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
}

.banner-links:hover {
  background-color: goldenrod;
}
