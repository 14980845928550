.project-links {
  text-decoration: none;
}

.cards {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  transition: .5s;
  width: 200px;
  margin: 40px;
  background-color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
}

.card-img {
  border: 3px solid goldenrod;
}

.cards:hover {
  box-shadow: 6px 6px 6px 6px black;;
}

.elements {
  color: white;
  text-decoration: none;
  padding: 2px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.head {
  text-decoration: none;
  font-size: 25px;
  color: white;
}

.title {
  text-decoration: none;
  color: white;
  font-size: 35px;
}

.paragraph {
  color: white;
  text-decoration: none;
}

